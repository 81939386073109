const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;
export const addToMap = async (isLoggedIn, courseProperties) => {
    console.log('Add to Map Clicked');
    if (!isLoggedIn) {
        alert('You must be logged in to add to map');
        return;
    }

    const token = localStorage.getItem('token');
    console.log('Token Retrieved:', token);

    if (!courseProperties || !courseProperties.id) {
        console.error('No course properties or course ID found');
        return;
    }

    console.log('Course ID:', courseProperties.id);

    try {
        const response = await fetch(
            `${backendUrl}/myapp/add_to_map/${courseProperties.id}/`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );

        console.log('Response Status:', response.status);
        const data = await response.json();
        console.log('Response Data:', data);
    } catch (error) {
        console.error('Error adding course to map:', error);
    }
};

export const deleteFromMap = async (isLoggedIn, courseProperties) => {
    console.log('Delete from Map Clicked');
    if (!isLoggedIn) {
        alert('You must be logged in to delete from map');
        return;
    }

    const token = localStorage.getItem('token');
    console.log('Token Retrieved:', token);

    if (!courseProperties || !courseProperties.id) {
        console.error('No course properties or course ID found');
        return;
    }

    console.log('Course ID:', courseProperties.id);

    try {
        const response = await fetch(
            `${backendUrl}/myapp/delete_from_map/${courseProperties.id}/`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );

        console.log('Response Status:', response.status);
        const data = await response.json();
        console.log('Response Data:', data);
    } catch (error) {
        console.error('Error deleting course from map:', error);
    }
};

export const checkCourseInUserMap = async (courseId, token) => {
    try {
        const response = await fetch(
            `${backendUrl}/myapp/check_course_in_user_map/${courseId}/`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );

        if (response.status === 200) {
            const data = await response.json();
            return data;
        } else {
            throw new Error('Course not found in user map');
        }
    } catch (error) {
        console.error('Error checking course in user map:', error);
        throw error;
    }
};

export const checkCourseInUserCustomMap = async (courseId, token) => {
    try {
        const response = await fetch(
            `${backendUrl}/myapp/check_course_in_user_custom_map/${courseId}/`,
            {
                headers: { Authorization: `Token ${token}` },
            },
        );
        
        if (response.status === 200) {
            const data = await response.json();
            return data;
        } else {
            throw new Error('Course not found in user custom map');
        }
    } catch (error) {
        console.error('Error checking course in user custom map:', error);
        throw error;
    }
};