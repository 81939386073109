import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import golfFlagImage from '../assets/images/golf.png';
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon,
} from 'react-share';
import '../styles/UserCourses.css';
import AutosuggestComponent from '../components/AutosuggestComponent';
import { addToMap, deleteFromMap, checkCourseInUserMap } from './mapFunctions';
import UserCourseTable from '../components/UserCourseTable';
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";

const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;

function UserCourses({ isLoggedIn, username }) {
    const shareUrl = `http://findfairways.com/${username}`;
    const [userCourses, setUserCourses] = useState([]);
    const [editingCourseId, setEditingCourseId] = useState(null);
    const navigate = useNavigate();
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [value, setValue] = React.useState('1');
    const [isMobile, setIsMobile] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
        setIsMobile(isMobileDevice);
    }, []);

    const handleSuggestionClick = async (suggestion) => {
        if (!isLoggedIn) {
            console.log('User is not logged in');
            return;
        }
    
        const token = localStorage.getItem('token');
        try {
            const courseInMapResponse = await checkCourseInUserMap(suggestion.id, token);
            const courseDetailsResponse = await fetch(`${backendUrl}/myapp/courses/${suggestion.slug}`, { 
                headers: { Authorization: `Token ${token}` },
            });
            const courseDetails = await courseDetailsResponse.json();
        
            setSelectedCourse({
                id: suggestion.id,
                slug: suggestion.slug,
                name: courseDetails.course.course_name,
                city: courseDetails.course.city,
                state: courseDetails.course.state,
                address: courseDetails.course.course_address,
                isInUserMap: courseInMapResponse.is_in_user_map,
            });
        } catch (error) {
            console.error('Error fetching course details:', error);
        }
    };
    

    const addToMapHandler = async () => {
        if (selectedCourse && isLoggedIn) {
            await addToMap(isLoggedIn, { id: selectedCourse.id });
        }
    };
    
    const deleteFromMapHandler = async () => {
        if (selectedCourse && isLoggedIn) {
            await deleteFromMap(isLoggedIn, { id: selectedCourse.id });
        }
    };
    
    useEffect(() => {
        if (isLoggedIn) {
            fetch(backendUrl + '/myapp/user_courses/', {
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                },
            })
                .then(response => response.json())
                .then(data => {
                    setUserCourses(data);
                })
                .catch(error => console.error('Error:', error));
        }
    }, [isLoggedIn]);

    const submitScore = useCallback((courseId, score) => {
        if (isNaN(score)) {
            alert('Please enter a score');
            return;
        }

        const scoreInt = parseInt(score, 10);
        if (isNaN(scoreInt) || scoreInt < 0) {
            alert('Invalid score format. Score must be a non-negative number.');
            return;
        }

        fetch(`${backendUrl}/myapp/course/${courseId}/update_score/`, {
            method: 'POST',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ best_score: scoreInt }),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.error || 'Unable to update score');
                    });
                }
                return response.json();
            })
            .then(data => {
                if (data.error) {
                    throw new Error(`Server error: ${data.error}`);
                }
                setUserCourses(prevCourses =>
                    prevCourses.map(userCourse =>
                        userCourse.course.id === courseId
                            ? { ...userCourse, best_score: scoreInt }
                            : userCourse
                    )
                );
                setEditingCourseId(null);
            })
            .catch(error => {
                console.error('Error:', error);
                alert(error.message);
            });
    }, []);

    const golfFlagIcon = new L.Icon({
        iconUrl: golfFlagImage,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
    });
    const [userReviews, setUserReviews] = useState([]);
    const currentUserId = localStorage.getItem('username');
    useEffect(() => {
        if (isLoggedIn) {
            fetch(`${backendUrl}/myapp/user/${currentUserId}/reviews`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                },
            })
                .then(response => response.json())
                .then(data => {
                    setUserReviews(data);
                })
                .catch(error =>
                    console.error('Error fetching reviews:', error)
                );
        }
    }, [isLoggedIn, currentUserId]);


    return (
        <div className='user-courses-container'>
            <Tabs defaultValue={1}>
            <TabsList>
                <Tab value={1}>Add A Course</Tab>
                <Tab value={2}>My Course table</Tab>
                <Tab value={3}>My Course Map</Tab>
            </TabsList>
            <TabPanel value={1}> 
                <AutosuggestComponent
                    fetchSuggestionsUrl={backendUrl + "/myapp/api/courses/autocomplete/"}
                    placeholderText="Type a course name"
                    onSuggestionClicked={handleSuggestionClick}
                />
            
                {selectedCourse && (
                    <div>
                        <h3>{selectedCourse.name}</h3>
                        <h4>{selectedCourse.address}</h4>
                        {selectedCourse.isInUserMap ? (
                            <button onClick={deleteFromMapHandler}>Remove from My Courses</button>
                            ) : (
                            <button onClick={addToMapHandler}>Add to My Courses</button>
                        )}
                    </div>
                )}
            </TabPanel>
            <TabPanel value={2}><UserCourseTable userCourses={userCourses} layoutMode="grid-no-grow" isLoggedIn={isLoggedIn} updateScore={submitScore} deleteFromMapHandler={deleteFromMapHandler} userReviews={userReviews} /></TabPanel>
            <TabPanel value={3}><MapContainer
                center={[39.5, -98.35]}
                zoom={2}
                className='leaflet-map'
                scrollWheelZoom={false}
                touch={isMobile}
            >
                <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                {userCourses.map(userCourse => (
                    <Marker
                        key={userCourse.course.id}
                        position={[
                            userCourse.course.latitude,
                            userCourse.course.longitude,
                        ]}
                        center={[
                            userCourse.course.latitude,
                            userCourse.course.longitude,
                        ]}
                        icon={golfFlagIcon}
                    >
                        <Popup>
                            <a
                                href={`/courses/${userCourse.course.id}`}
                                onClick={e => {
                                    e.preventDefault();
                                    navigate(
                                        `/courses/${userCourse.course.id}`
                                    );
                                }}
                            >
                                {userCourse.course.course_name}
                            </a>
                            <br /> {userCourse.course.course_address}
                        </Popup>
                    </Marker>
                ))}
                <FullscreenControl />
            </MapContainer></TabPanel>
            </Tabs>

            <div className='social-share-buttons'>
                <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl}>
                <TwitterIcon size={32} round />
                </TwitterShareButton>
            </div>
        </div>
    );
}

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
  };
const Tab = styled(BaseTab)`
  font-family: 'IBM Plex Sans', sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: bold;
  background-color: transparent;
  width: 90%;
  line-height: 1;
  padding: 8px 12px;
  margin: 6px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.95rem;
`;

const TabsList = styled(BaseTabsList)(
    ({ theme }) => `
      width: 100%;
      max-width: 960px;
      min-width: 960px; // Consider the implications on smaller screens
      background-color: ${blue[500]};
      border-radius: 12px;
      margin-bottom: 16px;
      display: flex;
      align-items: center; // Removed !IMPORTANT for cleaner CSS
      justify-content: center; // Removed !IMPORTANT for cleaner CSS
      box-shadow: 0px 4px 6px ${
        theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.4)' : 'rgba(0,0,0, 0.2)'
      };
      @media (max-width: 1050px) {
          max-width: 90%;
          min-width: 80%; // This ensures a minimum width; adjust as needed
          margin-left: auto;
          margin-right: auto;
      }
    `
  );
export default UserCourses;
