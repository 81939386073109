import React, { useState,useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import '../styles/CourseReview.css';
const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;
const Star = ({ filled, onMouseEnter, onMouseLeave, onClick }) => {
    return (
        <span
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            style={{ cursor: 'pointer' }}
        >
            {filled ? '⭐️' : '▫️'}
        </span>
    );
};
const Dollars = ({ filled, onMouseEnter, onMouseLeave, onClick }) => {
    return (
        <span
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            style={{ cursor: 'pointer' }}
        >
            {filled ? '💰' : '▫️'}
        </span>
    );
};

const Greenspeed = ({ filled, onMouseEnter, onMouseLeave, onClick }) => {
    return (
        <span
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            style={{ cursor: 'pointer' }}
        >
            {filled ? '⚡' : '▫️'}
        </span>
    );
};

const Paceofplay = ({ filled, onMouseEnter, onMouseLeave, onClick }) => {
    return (
        <span
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            style={{ cursor: 'pointer' }}
        >
            {filled ? '🚀' : '▫️'}
        </span>
    );
};

const CourseReview = () => {
    const [headline, setHeadline] = useState('Great golf course!');
    const [selectedImage, setSelectedImage] = useState(null);
    const { courseId } = useParams();
    const [rating, setRating] = useState(1);
    const [price, setPrice] = useState(1);
    const [greenspeed, setGreenspeed] = useState(1);
    const [paceOfPlay, setPaceOfPlay] = useState(1);
    const [text, setText] = useState('');
    const [recommend, setRecommend] = useState(false);
    const [handicap, setHandicap] = useState('scratch_or_better');
    const [images, setImages] = useState([]);
    const navigate = useNavigate();
    const [hoverRating, setHoverRating] = useState(0);
    const [selectedRating, setSelectedRating] = useState(0);
    const [hoverPrice, setHoverPrice] = useState(0);
    const [selectedPrice, setSelectedPrice] = useState(0);
    const [hoverGreenspeed, setHoverGreenspeed] = useState(0);
    const [selectedGreenspeed, setSelectedGreenspeed] = useState(0);
    const [hoverPaceOfPlay, setHoverPaceOfPlay] = useState(0);
    const [selectedPaceOfPlay, setSelectedPaceOfPlay] = useState(0);
    const [course, setCourse] = useState(null);

    useEffect(() => {

        const fetchCourseDetails = async () => {
            try {
                const response = await fetch(`${backendUrl}/myapp/courses/${courseId}/`);
                const data = await response.json();
                setCourse(data.course);
            } catch (error) {
                console.error('Error fetching course details:', error);
            }
        };

        fetchCourseDetails();
    }, [courseId]);

    if (!course) {
        return <div>Loading...</div>;
    }

    const handlePriceMouseEnter = price => {
        setHoverPrice(price);
    };

    const handlePriceMouseLeave = () => {
        setHoverPrice(0);
    };

    const handlePriceClick = price => {
        setSelectedPrice(price);
        setPrice(price);
    };

    const handleMouseEnter = rating => {
        setHoverRating(rating);
    };
    const handicapOptions = [
        'scratch_or_better',
        'single_digit',
        '10-20',
        '20+',
    ];

    const handleMouseLeave = () => {
        setHoverRating(0);
    };

    const handleClick = rating => {
        setSelectedRating(rating);
        setRating(rating);
    };

    const handleGreenspeedMouseEnter = greenspeed => {
        setHoverGreenspeed(greenspeed);
    };

    const handleGreenspeedMouseLeave = () => {
        setHoverGreenspeed(0);
    };

    const handleGreenspeedClick = greenspeed => {
        setSelectedGreenspeed(greenspeed);
        setGreenspeed(greenspeed);
    };

    const handlePaceOfPlayMouseEnter = paceOfPlay => {
        setHoverPaceOfPlay(paceOfPlay);
    };

    const handlePaceOfPlayMouseLeave = () => {
        setHoverPaceOfPlay(0);
    };

    const handlePaceOfPlayClick = paceOfPlay => {
        setSelectedPaceOfPlay(paceOfPlay);
        setPaceOfPlay(paceOfPlay);
    };
    const handleHeadlineChange = value => {
        setHeadline(value);
    };
    const handleImageClick = image => {
        setSelectedImage(image);
    };
    const handleRemoveClick = index => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };

    const renderImages = () => {
        return images.map((image, index) => (
            <div key={index} className='thumbnail-container'>
                <img
                    src={URL.createObjectURL(image)}
                    alt='Uploaded'
                    className='thumbnail'
                    onClick={() => handleImageClick(image)}
                />
                <button onClick={() => handleRemoveClick(index)}>Remove</button>
            </div>
        ));
    };

    const renderStars = () => {
        let stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <Star
                    key={i}
                    filled={
                        hoverRating >= i ||
                        (!hoverRating && selectedRating >= i)
                    }
                    onMouseEnter={() => handleMouseEnter(i)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleClick(i)}
                />
            );
        }
        return stars;
    };
    const renderDollarSigns = () => {
        let dollars = [];
        for (let i = 1; i <= 5; i++) {
            dollars.push(
                <Dollars
                    key={i}
                    filled={
                        hoverPrice >= i || (!hoverPrice && selectedPrice >= i)
                    }
                    onMouseEnter={() => handlePriceMouseEnter(i)}
                    onMouseLeave={handlePriceMouseLeave}
                    onClick={() => handlePriceClick(i)}
                />
            );
        }
        return dollars;
    };

    const renderSpeedSymbols = () => {
        let speed = [];
        for (let i = 1; i <= 5; i++) {
            speed.push(
                <Greenspeed
                    key={i}
                    filled={
                        hoverGreenspeed >= i ||
                        (!hoverGreenspeed && selectedGreenspeed >= i)
                    }
                    onMouseEnter={() => handleGreenspeedMouseEnter(i)}
                    onMouseLeave={handleGreenspeedMouseLeave}
                    onClick={() => handleGreenspeedClick(i)}
                />
            );
        }
        return speed;
    };

    const renderPaceOfPlaySymbols = () => {
        let play = [];
        for (let i = 1; i <= 5; i++) {
            play.push(
                <Paceofplay
                    key={i}
                    filled={
                        hoverPaceOfPlay >= i ||
                        (!hoverPaceOfPlay && selectedPaceOfPlay >= i)
                    }
                    onMouseEnter={() => handlePaceOfPlayMouseEnter(i)}
                    onMouseLeave={handlePaceOfPlayMouseLeave}
                    onClick={() => handlePaceOfPlayClick(i)}
                />
            );
        }
        return play;
    };
    const handleReviewSubmit = async e => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('rating', rating);
        formData.append('text', text);
        formData.append('recommend', recommend);
        formData.append('handicap', handicap);
        formData.append('price', price);
        formData.append('greenspeed', greenspeed);
        formData.append('pace_of_play', paceOfPlay);
        formData.append('headline', headline);

        images.forEach((image, index) => {
            formData.append(`image${index + 1}`, image);
        });

        const token = localStorage.getItem('token');

        fetch(
            `${backendUrl}/myapp/courses/${courseId}/submit_review/`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Token ${token}`,
                },
                body: formData,
            }
        )
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setRating(1);
                setText('');
                setRecommend(false);
                setHandicap('scratch_or_better');
                setImages([]);
                alert('Thank you for your review!');
                navigate(`/courses/${courseId}`);
            })
            .catch(error => console.error('Error submitting review:', error));
    };

    const handleRecommendationChange = value => {
        setRecommend(value);
    };

    const handleHandicapChange = value => {
        setHandicap(value);
    };

    return (
        <div className='review-form-container'>
            <h2>Review for {course.course_name}</h2>
            <form className='review-form' onSubmit={handleReviewSubmit}>
                <div className='form-group'>
                    <label>Rating:</label>
                    <div>{renderStars()}</div>
                </div>
                <div className='form-group'>
                    <label>Price:</label>
                    <div>{renderDollarSigns()}</div>
                </div>
                <div className='form-group'>
                    <label>Greenspeed:</label>
                    <div>{renderSpeedSymbols()}</div>
                </div>
                <div className='form-group'>
                    <label>Pace of Play:</label>
                    <div>{renderPaceOfPlaySymbols()}</div>
                </div>
                <div className='form-group'>
                    <label>Headline:</label>
                    <textarea
                        value={headline}
                        onChange={e => handleHeadlineChange(e.target.value)}
                        required
                        maxLength={30}
                    />
                </div>
                <div className='form-group'>
                    <label>Review:</label>
                    <textarea
                        value={text}
                        onChange={e => setText(e.target.value)}
                        required
                        maxLength={1000}
                    />
                </div>
                <div className='form-group'>
                    <label>Recommend:</label>
                    <div>
                        <button
                            type='button'
                            className={
                                recommend ? 'button-active' : 'button-inactive'
                            }
                            onClick={() => handleRecommendationChange(true)}
                        >
                            Yes
                        </button>
                        <button
                            type='button'
                            className={
                                !recommend ? 'button-active' : 'button-inactive'
                            }
                            onClick={() => handleRecommendationChange(false)}
                        >
                            No
                        </button>
                    </div>
                </div>
                <div className='form-group'>
                    <label>Handicap:</label>
                    <div className='button-group'>
                        {handicapOptions.map(option => (
                            <button
                                key={option}
                                type='button'
                                className={
                                    handicap === option
                                        ? 'button-active'
                                        : 'button-inactive'
                                }
                                onClick={() => handleHandicapChange(option)}
                            >
                                {option}
                            </button>
                        ))}
                    </div>
                </div>
                <div className='form-group'>
                    <Dropzone
                        onDrop={acceptedFiles =>
                            setImages([...images, ...acceptedFiles])
                        }
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className='dropzone'>
                                <input {...getInputProps()} />
                                <p>Click to select files or drag 'n' drop</p>
                            </div>
                        )}
                    </Dropzone>
                    <div className='image-preview'>{renderImages()}</div>
                </div>

                <button type='submit'>Submit Review</button>
            </form>

            {selectedImage && (
                <div className='image-modal'>
                    <img
                        src={URL.createObjectURL(selectedImage)}
                        alt='Selected'
                    />
                    <button
                        onClick={() =>
                            handleRemoveClick(images.indexOf(selectedImage))
                        }
                    >
                        Remove
                    </button>
                    <button onClick={() => setSelectedImage(null)}>
                        Close
                    </button>
                </div>
            )}
        </div>
    );
};

export default CourseReview;
