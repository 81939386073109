import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';

function AutosuggestComponent({ fetchSuggestionsUrl, placeholderText, setValue, fieldName, onSuggestionClicked }) {
    const [value, setValueLocal] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    
    const onSuggestionSelected = (event, { suggestion }) => {
        if (setValue && fieldName) {
            setValue(fieldName, suggestion.course_name, { shouldValidate: true });
        }
        onSuggestionClicked(suggestion);
    };

    const onChange = (event, { newValue }) => {
        setValueLocal(newValue);
        if (setValue && fieldName) {
            setValue(fieldName, newValue, { shouldValidate: false });
        }
    };

    const onSuggestionsFetchRequested = async ({ value }) => {
        if (value.length >= 3 && value.length <=15 ) {
            const response = await fetch(`${fetchSuggestionsUrl}?query=${value}`);
            const data = await response.json();
            setSuggestions(data);
        } else {
            setSuggestions([]);
        }
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const getSuggestionValue = suggestion => suggestion.course_name;

    const renderSuggestion = suggestion => (
        <div>
            {suggestion.course_name} - {suggestion.city}, {suggestion.state}
        </div>
    );

    const inputProps = {
        placeholder: placeholderText,
        value,
        onChange: onChange,
    };


    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={onSuggestionSelected}
        />
    );
}

export default AutosuggestComponent;