import React, { useState, useEffect, useCallback, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "../styles/CourseDetail.css";
import { useForm } from "react-hook-form";
import YardsSlider from "./YardsSlider";
import golfFlagImage from "../assets/images/golf.png";
import { useNavigate } from "react-router-dom";
import { addToMap, deleteFromMap } from "./mapFunctions";
import AutosuggestComponent from "../components/AutosuggestComponent";

const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;


const CourseDetail = ({ isLoggedIn }) => {
    const navigate = useNavigate();
    const handleSuggestionClick = (suggestion) => {
        navigate(`/courses/${suggestion.slug}`); 
    };
    const popupRef = useRef(null);

    const goToCourseDetails = useCallback(
        (slug) => {
            navigate(`/courses/${slug}`);
        },
        [navigate]
    );
    const [map, setMap] = useState(null);
    const { register, handleSubmit, setValue } = useForm();
    const formValues = useRef({
        courseName: "",
        state: "",
        greenType: "",
        architect: "",
    });
    const [isPgaTour, setIsPgaTour] = useState(false);
    const [lengthRange, setLengthRange] = useState([0, 9000]);
    const [courseProperties, setCourseProperties] = useState(null);
    const [courseCount, setCourseCount] = useState(0);
    const [isCourseInUserMap, setIsCourseInUserMap] = useState(false);
    const [courseList, setCourseList] = useState([]);
    const [showCourseList, setShowCourseList] = useState(false);
    const [listVisible, setListVisible] = useState(false);
    const [architects, setArchitects] = useState([]);
    const [states, setStates] = useState([]);
    const [architectInput, setArchitectInput] = useState("");
    const [stateInput, setStateInput] = useState("");

    useEffect(() => {
        const fetchArchitects = async () => {
            try {
                const response = await fetch(backendBaseUrl + '/myapp/architect_list/unique/');
                if (response.ok) {
                    const data = await response.json();
                    setArchitects(data);
                } else {
                    throw new Error("Network response was not ok.");
                }
            } catch (error) {
                console.error("Error fetching architects:", error);
            }
        };

        const fetchStates = async () => {
            try {
                const response = await fetch(backendBaseUrl + '/myapp/state_counts/');
                if (response.ok) {
                    const data = await response.json();
                    setStates(data);
                } else {
                    throw new Error("Network response was not ok.");
                }
            } catch (error) {
                console.error("Error fetching states:", error);
            }
        };

        fetchArchitects();
        fetchStates();
    }, []);
    const loadCustomIcon = (map) => {
        map.loadImage(golfFlagImage, (error, image) => {
            if (error) throw error;
            map.addImage("golf", image);
        });
    };

    const toggleCourseList = () => {
        setShowCourseList(!showCourseList);
    };
    const toggleListVisibility = () => {
        setListVisible(!listVisible);
    };

    const checkIfCourseInUserModel = useCallback(
        async (courseId) => {
            if (!isLoggedIn) {
                setIsCourseInUserMap(false);
                return;
            }

            const token = localStorage.getItem("token");
            try {
                const response = await fetch(`${backendBaseUrl}/myapp/check_course_in_user_map/${courseId}/`,
                    {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    }
                );
                const data = await response.json();
                setIsCourseInUserMap(data.is_in_user_map);
            } catch (error) {
                console.error("Error checking course in user map:", error);
            }
        },
        [isLoggedIn]
    );

    const updateMapWithCourses = useCallback(
        (courses) => {
            if (map.getLayer("courses")) {
                map.removeLayer("courses");
            }

            if (map.getSource("courses")) {
                map.removeSource("courses");
            }

            map.addSource("courses", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: courses.map((course) => ({
                        type: "Feature",
                        properties: {
                            id: course.id,
                            name: course.course_name,
                            address: course.course_address,
                            city: course.city,
                            state: course.state,
                            architect: course.architect,
                            length: course.length,
                            year_built: course.year_built,
                            green_type: course.greens,
                            fairway_type: course.fairways,
                            rating: course.rating,
                            driving_range: course.driving_range,
                            carts: course.carts,
                            rental_clubs: course.rental_clubs,
                            length_rank: course.length_rank,
                            slug: course.slug,
                        },
                        geometry: {
                            type: "Point",
                            coordinates: [course.longitude, course.latitude],
                        },
                    })),
                },
            });

            map.addLayer({
                id: "courses",
                type: "symbol",
                source: "courses",
                layout: {
                    "icon-image": "golf",
                    "icon-size": 0.1,
                    "icon-allow-overlap": false,
                },
            });

            map.on("click", "courses", function (e) {
                const features = map.queryRenderedFeatures(e.point, { layers: ["courses"] });
              
                if (!features.length) {
                  return;
                }
              
                const properties = features[0].properties;
                setCourseProperties(properties);
                checkIfCourseInUserModel(properties.id);
              
                if (popupRef.current) {
                  popupRef.current.remove();
                }
              
                const popupHtml = `
                  <h3>${properties.name}</h3>
                  <p>${properties.address}</p>
                  <p>${properties.city}, ${properties.state}</p>
                  <button id="view-course-details">Learn More</button>
                `;
              
                const popup = new mapboxgl.Popup({ className: "mapbox-popup" })
                    .setLngLat(e.lngLat)
                    .setHTML(popupHtml)
                    .addTo(map);
              
                popupRef.current = popup;

                const viewCourseDetailsButton = document.getElementById("view-course-details");
                
                if (viewCourseDetailsButton) {
                    viewCourseDetailsButton.onclick = () => {
                      goToCourseDetails(properties.slug);
                    };
                  }
            });
        },
        [map, checkIfCourseInUserModel, goToCourseDetails]
    );

    const loadCourses = useCallback(async () => {
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
        const courseName = formValues.current.courseName
            ? formValues.current.courseName
            : "";

        const queryParams = new URLSearchParams({
            course_name: courseName,
            state: stateInput,
            green_type: formValues.current.greenType,
            architect: architectInput,
            is_pga_tour: isPgaTour ? "TRUE" : "",
        });

        if (lengthRange[0] !== 0 || lengthRange[1] !== 9000) {
            queryParams.append("length_Min", lengthRange[0]);
            queryParams.append("length_Max", lengthRange[1]);
        }

        try {
            const response = await fetch(`${backendBaseUrl}/myapp/search_courses/?${queryParams.toString()}`
            );
            const data = await response.json();
            updateMapWithCourses(data.courses);
            setCourseCount(data.course_count);
            setCourseList(data.courses);
        } catch (error) {
            console.error("Error loading courses:", error);
        }
    }, [
        formValues,
        isPgaTour,
        architectInput,
        stateInput,
        lengthRange,
        updateMapWithCourses,
    ]);

    const addToMapHandler = async () => {
        addToMap(isLoggedIn, courseProperties);

    };

    const deleteFromMapHandler = async () => {
        deleteFromMap(isLoggedIn, courseProperties);

    };

    const renderButtons = () => {
        if (!isLoggedIn) {
            return (
                <p>
                    Create an account to build out your own personal course map.
                </p>
            );
        } else if (isCourseInUserMap) {
            return (
                <>
                    <p>In your courses</p>
                    <button onClick={deleteFromMapHandler}>
                        Remove from My Courses
                    </button>
                </>
            );
        } else {
            return <button onClick={addToMapHandler}>Add to My Courses</button>;
        }
    };

    useEffect(() => {
        const newMap = new mapboxgl.Map({
            container: "map",
            style: "mapbox://styles/mapbox/standard-beta",
            center: [-100, 39],
            zoom: 2.9,
            background: "white",
            distanceContainer: true,
            cooperativeGestures:true
        });

        newMap.on("load", () => {
            loadCustomIcon(newMap);
            setMap(newMap);
        });

        return () => {
            if (newMap) {
                newMap.remove();
            }
        };
    }, []);

    const onSubmit = (data) => {
        formValues.current = data;
        loadCourses();
        setShowCourseList(true);
        setListVisible(true);
    };

    const [expandedCourseId, setExpandedCourseId] = useState(null);

    function CourseList({ courses }) {
        const navigate = useNavigate();
      
        const toggleCourseDetails = (courseId) => {
          setExpandedCourseId(expandedCourseId === courseId ? null : courseId);
        };
        
        const flyToCourse = (course) => {
            if (map && course.longitude && course.latitude) {
                const adjustment = .06;
                map.flyTo({
                    center: [course.longitude - adjustment, course.latitude],
                    zoom: 10
                });
                }
            };
        
        const onCourseItemClick = async (course) => {
            flyToCourse(course);
            const properties = {
                id: course.id,
                name: course.course_name,
                address: course.course_address,
                city: course.city,
                state: course.state,
                architect: course.architect,
                length: course.length,
                Built: course.year_built,
                greens: course.greens,
                fairways: course.fairways,
                rating: course.rating,
                driving_range: course.driving_range,
                carts: course.carts,
                rental_clubs: course.rental_clubs,
                length_rank: course.length_rank,
                slug: course.slug,
            };


            setCourseProperties(properties);
            toggleCourseDetails(properties.id);
            await checkIfCourseInUserModel(properties.id);
            if (popupRef.current) {
                popupRef.current.remove();
            }

            const popup = new mapboxgl.Popup({ className: "mapbox-popup" })
                .setLngLat([course.longitude, course.latitude])
                .setHTML(
                    `
            <h3>   </h3>
            <br/>
            <h3>${properties.name}</h3>
            <p>${properties.address}</p>
            <p>${properties.city}, ${properties.state}</p>
            <p<${properties.architect}</p>
            <button id="view-course-details">View Course Details</button>
        `
                )
                .addTo(map);
                flyToCourse(course)

            popupRef.current = popup;

            const viewCourseDetailsButton = document.getElementById(
                "view-course-details"
            );
            if (viewCourseDetailsButton) {
                viewCourseDetailsButton.addEventListener("click", () => {
                    goToCourseDetails(properties.slug);
                });
            }
        };
      
        return (
            <div className="course-list.show">
              {courses.map((course) => (
                <div key={course.id} className="course-item">
                  <div
                    className="course-info"
                    onClick={() => {
                      onCourseItemClick(course);
                      toggleCourseDetails(course.id);
                    }}
                  >
                    <h4 className="course-name">
                      <a
                        href={`/courses/${course.slug}`}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/courses/${course.slug}`);
                        }}
                      >
                        {course.course_name}
                      </a>
                    </h4>
                    <p className="course-location">
                      {course.city}, {course.state}
                    </p>
                    {course.is_pga_tour && <div className="icon">🏆 PGA Tour Venue</div>}
                    {course.driving_range && <div className="icon">🏌️‍♂️ Driving Range</div>}
                    {course.length > 7000 && <div className="icon"> 💪🏼 7000+ Yards</div>}
                  </div>
                    {expandedCourseId === course.id && (
                        <div className="course-details">
                            <p>Rating: {course.rating}</p>
                            <p>Length: {course.length} yards</p>
                            <p>Built: {course.year_built}</p>
                            <p>Greens: {course.greens}</p>
                            <p>Fairways: {course.fairways}</p>
                            <p>Driving Range: {course.driving_range ? '✅' : '❌'}</p>
                            <p>Carts: {course.carts ? '✅' : '❌'}</p>
                            <p>Rental Clubs: {course.rental_clubs ? '✅' : '❌'}</p>
                            <p>Length Rank: {course.length_rank}</p>
                            <div>
                                <span>Architect(s): </span>
                                {(typeof course.architect === 'string' ? course.architect.split(',') : course.architect).map((name, index, array) => (
                                    <React.Fragment key={index}>
                                        <a href={`/architects/${encodeURIComponent(name.trim())}`} onClick={(e) => {
                                            e.preventDefault();
                                            navigate(`/architects/${encodeURIComponent(name.trim())}`);
                                        }}>
                                            {name.trim()}
                                        </a>{index < array.length - 1 ? ', ' : ''}
                                    </React.Fragment>
                                ))}
                            </div>
                            {renderButtons()}
                        </div>

                  )}
                </div>
              ))}
            </div>
          );
        }
    return (
        <div className="course-detail-container">
            <form onSubmit={handleSubmit(onSubmit)} className="search-form">
                <AutosuggestComponent
                    fetchSuggestionsUrl= {backendBaseUrl + "/myapp/api/courses/autocomplete/"}
                    setValue={setValue}
                    placeholderText="Course Name"
                    fieldName="courseName"
                    onSuggestionClicked={handleSuggestionClick}
                />
                <input
                    type="text"
                    placeholder="State"
                    {...register("state")}
                    list="states-datalist"
                    value={stateInput}
                    onChange={(e) => setStateInput(e.target.value)}
                />
                <datalist id="states-datalist">
                    {states.map((state, index) => (
                        <option key={index} value={state.state}>
                            {state.state} ({state.state_count} total courses)
                        </option>
                    ))}
                </datalist>
                <input
                    type="text"
                    placeholder="Green Type"
                    {...register("greenType")}
                />
                <input
                    type="text"
                    placeholder="Architect"
                    {...register("architect")}
                    list="architects-datalist"
                    value={architectInput}
                    onChange={(e) => setArchitectInput(e.target.value)}
                />
                <datalist id="architects-datalist">
                    {architects.map((architect, index) => (
                        <option key={index} value={architect.unique_architect}>
                            ({architect.architect_count} total courses)
                        </option>
                    ))}
                </datalist>
                <input
                    type="checkbox"
                    checked={isPgaTour}
                    onChange={(e) => setIsPgaTour(e.target.checked)}
                    id="is_pga_tour"
                />
                <label htmlFor="is_pga_tour">PGA Tour Venue</label>

                <YardsSlider
                    value={lengthRange}
                    onChange={(newValue) => setLengthRange(newValue)}
                />
                <button
                    type="submit"
                    className="search-button"
                    onClick={toggleCourseList}
                >
                    Search
                </button>
                <p>{courseCount} courses found</p>
            </form>

            <div className="map-and-course-list-container">
                <div id="map" className="map-container"></div>

                {listVisible && (
                    <>
                        <div className="course-list show">
                            <CourseList courses={courseList} />
                        </div>
                        <button
                            className="course-list-toggle-icon on"
                            onClick={toggleListVisibility}
                            style={{ display: !listVisible }}
                        ></button>
                    </>
                )}
                {showCourseList && !listVisible && (
                    <button
                        className="course-list-toggle-icon"
                        onClick={toggleListVisibility}
                        style={{ display: listVisible }}
                    ></button>
                )}
            </div>
        </div>
    );
};
export default CourseDetail;