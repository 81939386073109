import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import {
    MaterialReactTable,
  } from 'material-react-table';
import L from 'leaflet';
import golfFlagImage from '../assets/images/golf.png';
import { Box} from '@mui/material';
import '../styles/Architects.css';

const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;

function Architects() {
    const navigate = useNavigate();
    const { architectName } = useParams();
    const [courses, setCourses] = useState([]);
    const golfFlagIcon = new L.Icon({
        iconUrl: golfFlagImage,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
    });

    useEffect(() => {
        const url = `${backendUrl}/myapp/architects/${encodeURIComponent(
            architectName
        )}/`;
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setCourses(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [architectName]);

    const columns = useMemo(() => [
        {
            accessorKey: 'course_name',
            header: 'Name',
            Cell: ({ row }) => (
                <a href={`/courses/${row.original.slug}`}
                   onClick={(e) => {
                       e.preventDefault(); 
                       navigate(`/courses/${row.original.slug}`);
                   }}
                   style={{ cursor: 'pointer', color: '#1976d2', textDecoration: 'underline' }} 
                >
                    {row.original.course_name}
                </a>
            ),
        },
        
        {
            accessorKey: 'city',
            header: 'City',
        },
        {
            accessorKey: 'state',
            header: 'State',
        },
        {
            accessorKey: 'year_built',
            header: 'Year Built',
        },
        {
            accessorKey: 'length',
            header: 'Length',
        },
        {
            accessorKey: 'par',
            header: 'Par',
        },
        {
            accessorKey: 'greens',
            header: 'Green Type',
        },
        {
            accessorKey: 'rating',
            header: 'Rating',
        },
    ], [navigate]);

    return (
        <Box width="100%" overflow="auto" margin="5px">
            <MapContainer
                center={[39.5, -98.35]}
                zoom={3.5}
                scrollWheelZoom={false}
                style={{ height: '400px', width: '100%' }}
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {courses.map((course) => (
                    <Marker
                        key={course.id}
                        position={[course.latitude, course.longitude]}
                        icon={golfFlagIcon}
                    >
                        <Popup>
                            <a  href={`/courses/${course.id}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`/courses/${course.id}`);
                                }}
                            >
                                <h2>{course.course_name}</h2>
                            </a>
                            {course.city}, {course.state}
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
            <MaterialReactTable
                columns={columns}
                data={courses}
                layoutMode="grid-no-grow"
                muiTableContainerProps={{
                    sx: { 
                      maxHeight: '400px',
                      width: '100%',
                    },
                }}
            />
        </Box>
    );
}

export default Architects;