import styles from "../../styles/shared/footer.module.scss";
export default function Footer() {
  var d = new Date();
  var currentYear = d.getFullYear();
  var release = "0.0.1-alpha";
  return (
    <div className={styles.footerMain}>
      <div className={styles.footerTop}>
        <div className={styles.footerLeft}>
          <h4>Links</h4>
          <a href="/">Home</a>
          <a href="/">About Us</a>
          <a href="/">Privacy Policy</a>
        </div>
        <div class={styles.separator}></div>
        <div className={styles.footerRight}>
          <h4>Get In Touch</h4> <p>support@findfairways.com</p>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <footer>
          <small>
            &copy; Copyright {currentYear}, DataThemis llc. All Rights Reserved
          </small>
        </footer>
      </div>
      <div className={styles.releaseVersion}>
        <footer>      
          <small> Release: {release}</small>
        </footer>
      </div>
    </div>
  );
}
