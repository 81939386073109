import React, { useState, useEffect, useCallback, useMemo } from "react";
import Autosuggest from "react-autosuggest";
import { motion, AnimatePresence } from "framer-motion";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    useToast,
    Text,
    Image,
    Flex,
} from "@chakra-ui/react";
import { BiLike, BiChat, BiShare } from "react-icons/bi";
import { FileMosaic } from "@files-ui/react";
import golfbagimage from '../assets/images/golfclub.png';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { styled } from '@mui/system';
import { buttonClasses } from '@mui/base/Button';
import {
    MaterialReactTable,
  } from 'material-react-table';

const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;


function debounce(fn, delay) {
    let timeoutID = null;
    return function (...args) { 
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => { 
        fn.apply(this, args);
      }, delay);
    };
  }

function WITB() {
    const [clubs, setClubs] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedClub, setSelectedClub] = useState({});
    const [clubDetails, setClubDetails] = useState({
        shaft: "",
        degree: "",
        image: null,
    });
    const [suggestions, setSuggestions] = useState([]);
    const [selectedClubId, setSelectedClubId] = useState(null);
    const [showGrid, setShowGrid] = useState(false);
    const toast = useToast();

    const renderClubDetails = () => {
        const selectedClub = clubs.find((club) => club.id === selectedClubId);
        if (!selectedClub) return null;

        return (
            <>
                <Text fontSize="lg">{selectedClub.club.category}</Text>
                <Text fontSize="xl" fontWeight="bold">
                    {selectedClub.club.brand} {selectedClub.club.model}
                </Text>
                <Text>Shaft: {selectedClub.shaft || "N/A"}</Text>
                <Text>Degree: {selectedClub.degree || "N/A"}</Text>
                <Flex justifyContent="space-between" mt="4">
                    <Button leftIcon={<BiLike />}>Like</Button>
                    <Button leftIcon={<BiChat />}>Details</Button>
                    <Button leftIcon={<BiShare />}>Share</Button>
                    <Button
                        colorScheme="red"
                        leftIcon={<BiChat />}
                        onClick={() => handleRemoveClub(selectedClub.id)}
                    >
                        Remove
                    </Button>
                </Flex>
            </>
        );
    };

    const toggleGridVisibility = () => {
        setShowGrid(true);
    };

    const handleClubClick = (clubId, event) => {
        event.stopPropagation();
        setSelectedClubId(clubId);
    };
    const handleBackdropClick = () => {
        if (selectedClubId) {
            setSelectedClubId(null);
            setShowGrid(true);
        } else {
            setShowGrid(false);
        }
    };

    const fetchUserClubs = useCallback(() => {
        const token = localStorage.getItem("token");
        fetch(backendUrl + '/myapp/user_clubs/', {
            headers: {
                Authorization: `Token ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setClubs(data);
            })
            .catch((error) => {
                toast({
                    title: "Error fetching clubs",
                    description: error.toString(),
                    status: "error",
                });
            });
    }, [toast]);

    useEffect(() => {
        fetchUserClubs();
    }, [fetchUserClubs]);

    const fetchSuggestions = async (value) => {
        const response = await fetch(
            `${backendUrl}/myapp/api/clubs/autocomplete/?query=${value}`
        );
        const data = await response.json();
        setSuggestions(data);
    };

    const debouncedFetchSuggestions = useCallback(
        debounce((value) => {
          fetchSuggestions(value);
        }, 300),
        []
      );

    const onSuggestionSelected = useCallback((event, { suggestion }) => {
        setSelectedClub(suggestion);
        setSearchTerm(suggestion.model);
        setClubDetails({ shaft: "", degree: "", image: null });
        console.log("Selected Club:", suggestion);
    }, []);

    const onSuggestionsFetchRequested = useCallback(({ value }) => {
        debouncedFetchSuggestions(value);
    }, [debouncedFetchSuggestions]);

    const onSuggestionsClearRequested = useCallback(() => {
        setSuggestions([]);
    }, []);

    const getSuggestionValue = useCallback(
        (suggestion) => suggestion.model,
        []
    );

    const renderSuggestion = useCallback(
        (suggestion) => (
            <span>{`${suggestion.brand} ${suggestion.model} - ${suggestion.category}`}</span>
        ),
        []
    );

    const inputProps = {
        placeholder: "Add a club to your bag...",
        value: searchTerm,
        onChange: (_, { newValue }) => setSearchTerm(newValue),
    };

    const handleDetailChange = useCallback((e) => {
        const { name, value } = e.target;
        setClubDetails((prev) => ({ ...prev, [name]: value }));
    }, []);

    const handleImageChange = useCallback((e) => {
      const file = e.target.files[0];
      setClubDetails((prev) => ({ ...prev, image: file || null }));
  }, []);

  const handleAddClub = useCallback(async () => {
    if (!selectedClub || !selectedClub.id) {
        toast({
            title: "Cannot add club",
            description: "No club selected.",
            status: "error",
        });
        return;
    }

    const formData = new FormData();
    formData.append("club_id", selectedClub.id);
    formData.append("shaft", clubDetails.shaft);
    formData.append("degree", clubDetails.degree);

    if (clubDetails.image) {
        formData.append("image", clubDetails.image);
    } else {
        formData.append("use_default_image", "true");
    }

    try {
        const response = await fetch(`${backendUrl}/myapp/user_clubs/create/`, {
            method: "POST",
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
            },
            body: formData,
        });

        if (response.ok) {
            toast({
                title: "Club added successfully",
                description: "Your club has been added to your bag.",
                status: "success",
            });
            // Resetting the state to restart the process
            setSelectedClub({});
            setClubDetails({ shaft: "", degree: "", image: null });
            setSearchTerm("");
            fetchUserClubs(); // Refresh the list of clubs if needed
        } else {
            toast({
                title: "Error adding club",
                description: "There was a problem adding the club to your bag.",
                status: "error",
            });
        }
    } catch (error) {
        console.error("Error adding club:", error);
        toast({
            title: "Error adding club",
            status: "error",
            description: error.toString(),
        });
    }
}, [selectedClub, clubDetails, toast, fetchUserClubs]);


    const handleRemoveClub = useCallback(
        async (clubId) => {
            const token = localStorage.getItem("token");
            const response = await fetch(
                `${backendUrl}/myapp/user_clubs/delete/${clubId}/`,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );

            if (response.ok) {
                toast({
                    title: "Club removed successfully",
                    status: "success",
                });
                fetchUserClubs();
            } else {
                toast({ title: "Error removing club", status: "error" });
            }
        },
        [toast, fetchUserClubs]
    );

    const inputPropsShaft = {
        name: "shaft",
        value: clubDetails.shaft,
        onChange: handleDetailChange,
        placeholder: "Enter shaft details",
    };

    const inputPropsDegree = {
        name: "degree",
        type: "number",
        value: clubDetails.degree,
        onChange: handleDetailChange,
        placeholder: "Enter club's degree",
    };
    const columns = useMemo(() => [
        {
            id: 'actions',
            header: 'Actions',
            Cell: ({ row }) => (
              <Button 
                colorScheme="red" 
                size="sm" 
                onClick={() => handleRemoveClub(row.original.id)}
              >
                Remove
              </Button>
            ),
          },
        {

          accessorFn: row => row.club.brand,
          id: 'brand', 
          header: 'Brand',
        },
        {
          accessorFn: row => row.club.model,
          id: 'model',
          header: 'Model',
        },
        {
          accessorFn: row => row.club.category,
          id: 'category',
          header: 'Category',
        },
        {
          accessorKey: 'shaft',
          header: 'Shaft',
        },
        {
          accessorKey: 'degree',
          header: 'Degree',
          Cell: ({ cell }) => cell.getValue() ? `${cell.getValue()}°` : 'N/A',
        },
        {
          accessorKey: 'image_url',
          header: 'Image',
          Cell: ({ cell }) => cell.getValue() ? <Image src={`${backendUrl}/path/to/your/images/${cell.getValue()}`} alt="Club" boxSize="50px" objectFit="cover" /> : 'No Image',
        },
      ], [handleRemoveClub]);

    return (
        <div>
            <Tabs defaultValue={1}>
                <TabsList>
                    <Tab value={1}>Add A club</Tab>
                    <Tab value={2}>My WITB Animation</Tab>
                    <Tab value={3}>My WITB Table</Tab>
                </TabsList>

                <TabPanel value={1}> 

                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        onSuggestionSelected={onSuggestionSelected}
                    />
                    {selectedClub.id && (
                        <>
                            <FormControl>
                                <FormLabel>Shaft</FormLabel>
                                <Input {...inputPropsShaft} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Degree</FormLabel>
                                <Input {...inputPropsDegree} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Image</FormLabel>
                                <Input type="file" onChange={handleImageChange} />
                            </FormControl>
                            <FileMosaic />
                            <Button onClick={handleAddClub}>Add Club to Bag</Button>
                        </>
                    )}
                </TabPanel>
                <TabPanel value={2}> 
                    <AnimatePresence>
                    {!showGrid && !selectedClubId && (
                        <motion.img
                        src={golfbagimage}
                        alt="Golf Bag"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={toggleGridVisibility}
                        style={{
                            cursor: "pointer",
                            width: "50vw",
                            height: "50vh",
                            maxWidth: "600px",
                            maxHeight: "600px",
                        }}
                        />
                    )}
                    </AnimatePresence>

                    <AnimatePresence>
                        {showGrid && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                style={{
                                    display: "grid",
                                    gridTemplateColumns:
                                        "repeat(auto-fit, minmax(120px, 1fr))",
                                    gap: "15px",
                                    padding: "10%",
                                    maxWidth: "600px",
                                    margin: "0 auto",
                                    opacity: 100,
                                    position: "relative",
                                    zIndex: 6,
                                }}
                            >
                                {clubs.map((club) => (
                                    <motion.div
                                        key={club.id}
                                        layoutId={`club-${club.id}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClubClick(club.id, e);
                                        }}
                                        whileHover={{ scale: 1.05 }}
                                        style={{
                                            padding: "10px",
                                            border: "1px solid #ccc",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                            backgroundColor: "white",
                                        }}
                                    >
                                        <Text fontSize="sm">{club.club.category}</Text>
                                        <Text fontSize="md" fontWeight="bold">
                                            {club.club.brand} {club.club.model}
                                        </Text>
                                        <Image src={`${backendUrl}/myapp/media/clubs/${club.image_url}`} alt={club.club.model} />
                                    </motion.div>
                                ))}
                            </motion.div>
                        )}
                    </AnimatePresence>

                    <AnimatePresence>
                        {selectedClubId && (
                            <motion.div
                                layoutId={`club-${selectedClubId}`}
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.5 }}
                                style={{
                                    position: "fixed",
                                    top: "40vh",
                                    transform: "translate(-50%, -50%)",
                                    maxWidth: "80vw",
                                    maxHeight: "80vh",
                                    background: "white",
                                    padding: "1%",
                                    borderRadius: "10px",
                                    boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
                                    zIndex: 10,
                                    overflowY: "auto",
                                }}
                            >
                                {renderClubDetails()}
                                <Button mt="4" onClick={() => setSelectedClubId(null)}>
                                    Close
                                </Button>
                            </motion.div>
                        )}
                    </AnimatePresence>

                    {(showGrid || selectedClubId) && (
                        <Box
                            onClick={handleBackdropClick}
                            style={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "aliceblue",
                                opacity: 0.5,
                                zIndex: 4,
                            }}
                        />
                    )}

                </TabPanel>
                <TabPanel value={3}>
                <Box width="90%" overflow="auto">
                    <MaterialReactTable
                        columns={columns}
                        data={clubs}
                        muiTableContainerProps={{
                            sx: { 
                              maxHeight: '400px',
                              width: '100%',
                            },
                        }}
                    />
                </Box>
                </TabPanel>
            </Tabs>
        </div>
    );
}

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
  };
const Tab = styled(BaseTab)`
  font-family: 'IBM Plex Sans', sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: bold;
  background-color: transparent;
  width: 90%;
  line-height: 1;
  padding: 8px 12px;
  margin: 6px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.95rem;
`;

const TabsList = styled(BaseTabsList)(
    ({ theme }) => `
      width: 100%;
      max-width: 960px;
      min-width: 960px; // Consider the implications on smaller screens
      background-color: ${blue[500]};
      border-radius: 12px;
      margin-bottom: 16px;
      display: flex;
      align-items: center; // Removed !IMPORTANT for cleaner CSS
      justify-content: center; // Removed !IMPORTANT for cleaner CSS
      box-shadow: 0px 4px 6px ${
        theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.4)' : 'rgba(0,0,0, 0.2)'
      };
      @media (max-width: 1050px) {
          max-width: 90%;
          min-width: 80%; // This ensures a minimum width; adjust as needed
          margin-left: auto;
          margin-right: auto;
      }
    `
  );
        

export default WITB;