import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import CourseDetail from "./components/CourseDetail";
import SignInSide from "./components/SignInSide";
import IndividualCourse from "./components/IndividualCourse";
import LogOut from "./components/LogOut";
import SignUp from "./components/SignUp";
import Architects from "./components/Architects";
import "./styles/app.scss";
import UserCourses from "./components/UserCourses";
import CourseReview from "./components/CourseReview";
import Greens from "./components/Greens";
import PublicProfile from "./components/PublicProfile";
import CourseCompare from "./components/CourseCompare";
import Witb from "./components/Witb";
import Footer from "./components/shared/Footer";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState("");
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        const storedUsername = localStorage.getItem("username");
        if (token) {
            setIsLoggedIn(true);
            setUsername(storedUsername);
        }
    }, []);

    const handleLogin = (user) => {
        setIsLoggedIn(true);
        setUsername(user.username);
        localStorage.setItem("token", user.token);
        localStorage.setItem("username", user.username);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        setUsername("");
        localStorage.removeItem("token");
        localStorage.removeItem("username");
    };
    const closeMenu = () => {
        setMenuOpen(false);
    };
    const ProtectedRoute = ({ children }) => {
        const [loading, setLoading] = useState(true);
    
        useEffect(() => {
            const checkAuth = async () => {
                const token = localStorage.getItem("token");
                setTimeout(() => {
                    setIsLoggedIn(!!token);
                    setLoading(false);
                }, 1000);
            };
    
            checkAuth();
        }, []);
    
        if (loading) {
            return <div>Loading...</div>;
        }
    
        return isLoggedIn ? children : <Navigate to="/login" />;
    };

    return (
        <div className="appBody">
        <Router>
            <div className="App">
                <nav className="nav-bar">
                    <div className="nav-home">
                        <Link to="/" onClick={closeMenu}>Home</Link>
                    </div>
                    {isLoggedIn ? (<><span className="nav-username" onClick={closeMenu}>
                                    Logged in as <b>{username}</b>
                                </span></>) : (<></>) }
                    <div className="hamburger-menu" onClick={() => setMenuOpen(!menuOpen)}>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                    <div className={`nav-links ${menuOpen ? "open" : ""}`}>
                        {isLoggedIn ? (
                            <>
                                 
                                <Link to="/usercourses" onClick={closeMenu} className="nav-item">
                                    My Courses
                                </Link>
                                <Link to="/compare" onClick={closeMenu} className="nav-item">
                                    Compare Courses
                                </Link>
                                <Link to="/witb" onClick={closeMenu} className="nav-item">
                                    WITB
                                </Link>
                                <Link
                                    to="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleLogout();
                                    }}
                                    className="nav-item"
                                >
                                    Log Out of <b>{username}</b>
                                </Link>
                            </>
                        ) : (
                            <>
                                <Link to="/login" onClick={closeMenu} className="nav-item">
                                    Log In
                                </Link>
                                <Link to="/signup" onClick={closeMenu} className="nav-item">
                                    Sign Up
                                </Link>
                            </>
                        )}
                    </div>
                </nav>

                {/* Routing */}
                <Routes>
                    <Route
                        path="/login"
                        element={<SignInSide onLogin={handleLogin} />}
                    />
                    <Route
                        path="/signup"
                        element={<SignUp onLogin={handleLogin} />}
                    />
                    <Route path="/logout" element={<LogOut />} />
                    <Route
                        path="/courses/:courseSlug"
                        element={<IndividualCourse isLoggedIn={isLoggedIn} />}
                    />
                    <Route
                        path="/course-review/:courseSlug"
                        element={<CourseReview />}
                    />
                    <Route
                            path="/usercourses"
                            element={
                                <ProtectedRoute isLoggedIn={isLoggedIn}>
                                    <UserCourses isLoggedIn={isLoggedIn} username={username} />
                                </ProtectedRoute>
                            }
                        />
                    <Route
                        path="/"
                        element={<CourseDetail isLoggedIn={isLoggedIn} />}
                    />
                    <Route
                        path="/architects/:architectName"
                        element={<Architects />}
                    />
                    <Route path="/greens/:greenType" element={<Greens />} />
                    <Route
                        path="/:username"
                        element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <PublicProfile />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/compare" element={<CourseCompare />} />
                    <Route
                        path="/witb"
                        element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <Witb isLoggedIn={isLoggedIn} username={username} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                </Routes>
            </div>
        </Router>
        <Footer></Footer>
        </div>
    );
}

export default App;
