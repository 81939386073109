import React, { useState, useMemo } from 'react';
import { Tooltip } from '@chakra-ui/react';
import {
  MaterialReactTable,
} from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField } from '@mui/material';


const UserCourseTable = ({ userCourses, updateScore, userReviews}) => {
  const navigate = useNavigate();
  const [editState, setEditState] = useState({ id: null, score: '' });
  

  const columns = useMemo(() => [
    
    {
      accessorKey: 'course.course_name',
      header: 'Name',
      Cell: ({ row }) => {
          const courseSlug = row.original.course.slug; 
          const courseName = row.original.course.course_name; 
  
          return (
              <a
                  href={`/courses/${courseSlug}`}
                  onClick={(e) => {
                      e.preventDefault();
                      navigate(`/courses/${courseSlug}`); 
                  }}
                  style={{ cursor: 'pointer', color: '#1976d2', textDecoration: 'underline' }}
              >
                  {courseName}
              </a>
          );
      },
  },
          
    {
      accessorFn: (row) => `${row.course.city}, ${row.course.state}`,
      id: 'location',
      header: 'Location',
    },
    {
        accessorKey: 'course.architect',
        header: 'Architect',
        maxSize: 200,
        Cell: ({ row }) => {
          const architects = row.original.course.architect.replace(/[\\[\]"]/g, '').split(',').map(name => name.trim());
          return (
            <div>
              {architects.map((architectName, index) => (
                <React.Fragment key={index}>
                  <Tooltip hasArrow label={`More courses by ${architectName}`} bg='limegreen'>
                    <a
                      href={`/architects/${encodeURIComponent(architectName)}`}
                      onClick={e => {
                        e.preventDefault();
                        navigate(`/architects/${encodeURIComponent(architectName)}`);
                      }}
                      style={{ cursor: 'pointer', color: '#1976d2', textDecoration: 'underline' }}
                    >
                      {architectName}
                    </a>
                  </Tooltip>
                  {index < architects.length - 1 ? ', ' : ''}
                </React.Fragment>
              ))}
            </div>
          );
        },
    },
    {
      accessorKey: 'course.length',
      header: 'Length',
      maxSize: 25,
    },
    {
        accessorKey: 'course.greens',
        header: 'Green Type',
        Cell: ({ row }) => {
          const greenType = row.original.course.greens;
          return (
            <Tooltip hasArrow label={`All courses with ${greenType}`} bg='limegreen'>
              <a
                href={`/greens/${encodeURIComponent(greenType)}`} 
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/greens/${encodeURIComponent(greenType)}`);
                }}
                style={{ cursor: 'pointer', color: '#1976d2', textDecoration: 'underline' }}
              >
                {greenType}
              </a>
            </Tooltip>
          );
        },
      },
      
    {
      accessorKey: 'course.rating',
      header: 'Rating',
      maxSize: 25,
    },
    {
      accessorKey: 'best_score',
      header: 'Best Score',
      Cell: ({ row }) => {
        const isEditing = editState.id === row.original.course.id;
        return (
          <div>
            {isEditing ? (
              <>
                <TextField
                  size="small"
                  value={editState.score}
                  onChange={(e) => setEditState({ ...editState, score: e.target.value })}
                  type="number"
                />
                <Button onClick={() => {
                  updateScore(row.original.course.id, editState.score);
                  setEditState({ id: null, score: '' });
                }}>Save</Button>
                <Button onClick={() => setEditState({ id: null, score: '' })}>Cancel</Button>
              </>
            ) : (
              <>
                {row.original.best_score || 'N/A'}
                <Button onClick={() => setEditState({ id: row.original.course.id, score: row.original.best_score || '' })}>Edit</Button>
              </>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'course.id',
      header: 'Review',
      Cell: ({ row }) => {
        const userReview = userReviews.find(review => review.course_id === row.original.course.id);
        const userRating = userReview ? userReview.rating : null;

        return userRating ? (
          <>
            {Array.from({ length: userRating }, (_, i) => (
              <span key={i}>✪</span>
            ))}
          </>
        ) : (
          <button onClick={() => navigate(`/course-review/${row.original.course.id}`)}>
            Review this course
          </button>
        );
      },
    },
  ], [navigate, updateScore, editState, userReviews]);

  return (
    <Box width="95%" overflow="auto">
    <MaterialReactTable
        columns={columns}
        data={userCourses}
        getRowId={(row) => row.course.id}
        renderDetailPanel={({ row }) => (
          <Box p={2}>
          <div>
            <p>{`Built in ${row.original.course.year_built}`} {row.original.course.course_name} is a {row.original.course.state} course</p>
            <p>{`Par: ${row.original.course.par}`}</p>
          </div>

        </Box>
        )}
        enableRowActions
        enableExpanding
        muiDetailPanelAccordionProps={{ elevation: 2 }}
      />
    </Box>
  );
};

export default UserCourseTable;
