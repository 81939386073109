import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from 'leaflet';
import golfFlagImage from "../assets/images/golf.png";
import {Tooltip, Text, Link } from "@chakra-ui/react";
import AutosuggestComponent from "./AutosuggestComponent";
import { useNavigate } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "../styles/CourseCompare.css"

const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const CourseCompare = () => {
    const [courseId1, setCourseId1] = useState(null);
    const [courseId2, setCourseId2] = useState(null);
    const [courseDetails1, setCourseDetails1] = useState(null);
    const [courseDetails2, setCourseDetails2] = useState(null);
    const navigate = useNavigate();
    const mapCenter = [37.0902, -95.7129];
    const golfFlagIcon = new L.Icon({
        iconUrl: golfFlagImage,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
    });

    useEffect(() => {
        if (courseId1) fetchCourseDetails(courseId1, setCourseDetails1);
        if (courseId2) fetchCourseDetails(courseId2, setCourseDetails2);
    }, [courseId1, courseId2]);

    const fetchCourseDetails = async (courseId, setCourseDetails) => {
        const response = await fetch(
            `${backendUrl}/myapp/courses/${courseId}/`
        );
        if (!response.ok) {
            console.error("Failed to fetch course details");
            return;
        }
        const data = await response.json();
        setCourseDetails(data.course);
    };
    const renderMap = (course1, course2) => {
        return (
            <MapContainer
                center={mapCenter}
                zoom={3.5}
                scrollWheelZoom={false}
                style={{ height: "400px", width: "100%", maxWidth: "800px", margin: "0 auto", zIndex: 1 }}
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {course1 && (
                    <Marker position={[course1.latitude, course1.longitude]} icon={golfFlagIcon}>
                        <Popup>
                            <Link onClick={() => navigate(`/courses/${course1.slug}`)} style={{ cursor: 'pointer', color: '#007bff' }}>
                                {course1.course_name}
                            </Link>
                        </Popup>
                    </Marker>
                )}
                {course2 && (
                    <Marker position={[course2.latitude, course2.longitude]} icon={golfFlagIcon}>
                        <Popup>
                            <Link onClick={() => navigate(`/courses/${course2.slug}`)} style={{ cursor: 'pointer', color: '#007bff' }}>
                                {course2.course_name}
                            </Link>
                        </Popup>
                    </Marker>
                )}
            </MapContainer>
        );
    };

    const renderComparisonTable = (course1, course2) => {
        if (!course1 || !course2)
            return <Text>Please select two courses to compare.</Text>;
    
        const properties = [
            { label: "Length", key: "length", unit: "yards", isNumeric: true },
            { label: "Slope", key: "slope", unit: "", isNumeric: true },
            { label: "Rating", key: "rating", unit: "", isNumeric: true },
            { label: "Built", key: "year_built", unit: "", isNumeric: true },
            { label: "Par", key: "par", unit: "", isNumeric: true },
            { label: "Greens", key: "greens" },
            { label: "Architect", key: "architect", isCustom: true },
        ];
    
        return (
            <>
                            <div className="course-names">
                <Text fontSize="lg" fontWeight="bold">
                    <Link onClick={() => navigate(`/courses/${course1.slug}`)} style={{ cursor: 'pointer', color: '#1976d2' }}>
                        {course1.course_name}
                    </Link>
                    {' '}vs.{' '}
                    <Link onClick={() => navigate(`/courses/${course2.slug}`)} style={{ cursor: 'pointer', color: '#1976d2' }}>
                        {course2.course_name}
                    </Link>
                </Text>
            </div>
                <div className="comparison-table">
                    {properties.map(({ label, key, unit, isNumeric, isCustom }) => (
                        <div className="comparison-item" key={key}>
                            <span>{label}:</span>
                            {!isCustom ? (
                                <>
                                    <div className="comparison-column">
                                        <span className={isNumeric && course1[key] > course2[key] ? "better" : ""}>
                                            {`${course1[key]} ${isNumeric ? unit : ""}`.trim()}
                                        </span>
                                    </div>
                                    <div className="comparison-column">
                                        <span className={isNumeric && course2[key] > course1[key] ? "better" : ""}>
                                            {`${course2[key]} ${isNumeric ? unit : ""}`.trim()}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="comparison-column">
                                        {architectColumn(course1)}
                                    </div>
                                    <div className="comparison-column">
                                        {architectColumn(course2)}
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </>
        );
    };
    

    const architectColumn = (course) => {
        const architectNames = course.architect.split(",").map(name => name.trim());
        return architectNames.map((name, index) => (
        <Tooltip key={index} hasArrow label={`More ${name} courses`} bg="orange">
            <Link 
                color="teal.500" 
                onClick={(e) => navigate(`/architects/${encodeURIComponent(name)}`)}
                style={{ cursor: 'pointer' }}
            >
                {name}{index < architectNames.length - 1 ? ", " : ""}
            </Link>
        </Tooltip>
        ));
    };

    return (
        <div className="container">
            <h2 className="title">Compare Golf Courses</h2>
            {renderMap(courseDetails1, courseDetails2)}
            <div className="search-boxes">
                <div className="search-box">
                <AutosuggestComponent
                    fetchSuggestionsUrl={backendUrl + "/myapp/api/courses/autocomplete/"}
                    placeholderText="Search for the first course"
                    onSuggestionClicked={setCourseId1}
                />
                </div>
                <div className="search-box">
                <AutosuggestComponent
                    fetchSuggestionsUrl={backendUrl + "/myapp/api/courses/autocomplete/"}
                    placeholderText="Search for the second course"
                    onSuggestionClicked={setCourseId2}
                />
                </div>
            </div>
            {courseDetails1 && courseDetails2 && renderComparisonTable(courseDetails1, courseDetails2)}
        </div>
    );
};

export default CourseCompare;