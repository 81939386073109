import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Box } from "@chakra-ui/react";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    FeatureGroup,
    Polyline,
} from 'react-leaflet';
import 'leaflet-gesture-handling';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { EditControl } from 'react-leaflet-draw';
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
import '../styles/IndividualCourse.css';
import golfFlagImage from '../assets/images/golf.png';
import { addToMap, deleteFromMap, checkCourseInUserMap } from './mapFunctions';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';


const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;
function IndividualCourse({ isLoggedIn }) {
    const [isMobile, setIsMobile] = useState(false);
    const [averageRating, setAverageRating] = useState(0);
    const navigate = useNavigate();
    const { courseId } = useParams();
    const { courseSlug } = useParams();
    const [course, setCourse] = useState(null);
    const [polylines, setPolylines] = useState([]);
    const [polylineGroup, setPolylineGroup] = useState(null);
    const [isCourseInUserMap, setIsCourseInUserMap] = useState(false);
    const [courseProperties] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [mapType, setMapType] = useState('standard');
    const [reviews, setReviews] = useState([]);
    const [recommendPercentage, setRecommendPercentage] = useState(0);
    const [reviewImages, setReviewImages] = useState([]);
    const shouldShowCarousel = reviewImages.length > 0;

    const fetchReviews = async () => {
        try {
            const reviewsResponse = await fetch(`${backendUrl}/myapp/courses/${course.id}/reviews/`);
            if (!reviewsResponse.ok) throw new Error('Failed to fetch reviews');
            const reviewsData = await reviewsResponse.json();
    
            setReviews(reviewsData);
            const imagesData = reviewsData.flatMap(review => [
                review.image1 ? `https://findfairways.com/${review.image1}` : null,
                review.image2 ? `https://findfairways.com/${review.image2}` : null,
            ]).filter(Boolean);
            setReviewImages(imagesData);
    
            if (reviewsData.length > 0) {
                const totalRating = reviewsData.reduce((total, review) => total + review.rating, 0);
                setAverageRating(totalRating / reviewsData.length);
                const recommendCount = reviewsData.filter(review => review.recommend).length;
                setRecommendPercentage(((recommendCount / reviewsData.length) * 100).toFixed(0));
            }
        } catch (error) {
            console.error('Error fetching reviews:', error);
        }
    };
    
    useEffect(() => {
        const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
        setIsMobile(isMobileDevice);
    }, []);

    const images = reviewImages.map((image, index) => ({
        original: image,
        thumbnail: image,
        originalAlt: `Review ${index + 1}`,
    }));
    useEffect(() => {
        const fetchData = async () => {
            try {
                const courseResponse = await fetch(`${backendUrl}/myapp/courses/${courseSlug}/`);
                if (!courseResponse.ok) throw new Error('Failed to fetch course details');
                const courseData = await courseResponse.json();

                setCourse(courseData.course);

                if (isLoggedIn) {
                    const userMapResponse = await checkCourseInUserMap(courseData.course.id, localStorage.getItem('token'));
                    setIsCourseInUserMap(userMapResponse.is_in_user_map);
                }

                const reviewsResponse = await fetch(`${backendUrl}/myapp/courses/${courseData.course.id}/reviews/`);
                if (!reviewsResponse.ok) throw new Error('Failed to fetch reviews');
                const reviewsData = await reviewsResponse.json();

                setReviews(reviewsData);
                if (reviewsData.length > 0) {
                    const images = reviewsData.flatMap(review => [
                        review.image1 ? `https://findfairways.com/${review.image1}` : null,
                        review.image2 ? `https://findfairways.com/${review.image2}` : null,
                    ]).filter(Boolean);
                    setReviewImages(images);

                    const totalRating = reviewsData.reduce((total, review) => total + review.rating, 0);
                    setAverageRating(totalRating / reviewsData.length);

                    const recommendCount = reviewsData.filter(review => review.recommend).length;
                    setRecommendPercentage(((recommendCount / reviewsData.length) * 100).toFixed(0));
                }
            } catch (error) {
                console.error('Error fetching course details or reviews:', error);
            }
        };

        fetchData();
    }, [courseSlug, isLoggedIn]);
    const renderReviewsList = () => {
        return reviews.map(review => (
            <div key={review.id} className='review'>
                <div className='review-stars'>
                    {Array(review.rating)
                        .fill()
                        .map((_, i) => (
                            <span key={i}>✪</span>
                        ))}
                    <p className='review-date'>
                        Reviewed on: {formatDate(review.created_at)}
                    </p>
                </div>
                <div className='review-content'>
                    <h3>{review.headline}</h3>
                    <div>{review.text}</div>
                    <div className='review-votes'>
                        <button
                            className='upvote-button'
                            onClick={() => handleUpvote(review.id)}
                        >
                            ↑
                        </button>
                        <span>({review.upvotes})</span>
                        <button
                            className='downvote-button'
                            onClick={() => handleDownvote(review.id)}
                        >
                            ↓
                        </button>
                        <span>({review.downvotes})</span>
                    </div>
                </div>
                <div className='review-user'>
                    <strong>{review.user}</strong>
                    <p>Handicap: {review.handicap}</p>
                </div>
            </div>
        ));
    };
    async function handleUpvote(reviewId) {
        const upvotedReviews =
            JSON.parse(localStorage.getItem('upvotedReviews')) || [];
        if (upvotedReviews.includes(reviewId)) {
            alert('You have already upvoted this review');
            return;
        }

        const response = await fetch(
            `${backendUrl}/myapp/reviews/${reviewId}/upvote/`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                },
            }
            
        );

        if (response.ok) {
            upvotedReviews.push(reviewId);
            localStorage.setItem(
                'upvotedReviews',
                JSON.stringify(upvotedReviews)
            );
            fetchReviews();
        }
    }

    async function handleDownvote(reviewId) {
        const downvotedReviews =
            JSON.parse(localStorage.getItem('downvotedReviews')) || [];
        if (downvotedReviews.includes(reviewId)) {
            alert('You have already downvoted this review');
            return;
        }

        const response = await fetch(
            `${backendUrl}/myapp/reviews/${reviewId}/downvote/`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                },
            }
        );

        if (response.ok) {
            downvotedReviews.push(reviewId);
            localStorage.setItem(
                'downvotedReviews',
                JSON.stringify(downvotedReviews)
            );
            fetchReviews();
        }
    }
    const formatDate = isoString => {
        const date = new Date(isoString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
    };
    const checkIfCourseInUserModel = async courseId => {
        if (!isLoggedIn) {
            setIsCourseInUserMap(false);
            return;
        }

        const token = localStorage.getItem('token');
        try {
            const response = await checkCourseInUserMap(courseId, token);
            setIsCourseInUserMap(response.is_in_user_map);
        } catch (error) {
            console.error('Error checking course in user map:', error);
        }
    };


    if (!course) {
        return <div className='loading'>Loading...</div>;
    }

    const position = [course.latitude, course.longitude];
    

    const golfFlagIcon = new L.Icon({
        iconUrl: golfFlagImage,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
    });

    const onCreated = e => {
        const layer = e.layer;
        if (e.layerType === 'polyline') {
            const latlngs = layer.getLatLngs();
            const distance = calculateDistance(latlngs);
            const yardDistance = convertMetersToYards(distance);
            const popupContent = `${yardDistance.toFixed(2)} yards`;
            layer.bindPopup(popupContent, { autoClose: false, closeButton: true }).openPopup();
            setPolylines([...polylines, { latlngs, distance: yardDistance, popupContent }]);
        }
        if (polylineGroup) {
            polylineGroup.addLayer(layer);
        }
    };


    const onDeleted = (e) => {
        const deletedLayers = e.layers;
        const deletedLayersIds = [];
        deletedLayers.eachLayer((layer) => {

            deletedLayersIds.push(layer._leaflet_id); 
        });
    

        const remainingPolylines = polylines.filter(polyline =>
            !deletedLayersIds.includes(polyline.latlngs._leaflet_id) 
        );
    
        setPolylines(remainingPolylines);
        if (polylineGroup) {
            deletedLayers.eachLayer((layer) => {
                polylineGroup.removeLayer(layer);
            });
        }
    };
    const onEdited = e => {
        const editedLayers = e.layers;
        let editedPolylines = [...polylines];
    
        editedLayers.eachLayer(layer => {

            const layerId = layer._leaflet_id;
            const updatedLatLngs = layer.getLatLngs();
            const distance = calculateDistance(updatedLatLngs);
            const yardDistance = convertMetersToYards(distance);
            const popupContent = `${yardDistance.toFixed(2)} yards`;

            layer.bindPopup(popupContent, { autoClose: false, closeButton: true }).openPopup();
            const polylineIndex = editedPolylines.findIndex(polyline => polyline.leaflet_id === layerId);
            if (polylineIndex !== -1) {
                editedPolylines[polylineIndex] = {
                    ...editedPolylines[polylineIndex],
                    latlngs: updatedLatLngs,
                    distance: yardDistance,
                    popupContent,
                    leaflet_id: layerId 
                };
            }
        });

        setPolylines(editedPolylines);
    };

    function calculateDistance(latlngs) {
        let totalDistance = 0;
        for (let i = 0; i < latlngs.length - 1; i++) {
            totalDistance += latlngs[i].distanceTo(latlngs[i + 1]);
        }
        return totalDistance;
    }

    function convertMetersToYards(meters) {
        return meters * 1.09361;
    }
    const renderSuccessMessage = () => {
        if (showSuccessMessage) {
            return (
                <div className='success-message'>
                    {successMessage}
                    <button onClick={() => setShowSuccessMessage(false)}>
                        Close
                    </button>
                </div>
            );
        }
        return null;
    };

    const renderButtons = () => {
        if (!isLoggedIn) {
            return (
                <p>
                    Create an account to build out your own personal course map.
                </p>
            );
        } else if (isCourseInUserMap) {
            return (
                <>
                    <button onClick={deleteFromMapHandler}>
                        Remove from My Courses
                    </button>
                </>
            );
        } else {
            return (
                <>
                    <button onClick={addToMapHandler}>Add to My Courses</button>
                </>
            );
        }
    };
    const addToMapHandler = async () => {
        try {
            const result = await addToMap(isLoggedIn, courseProperties);
            if (result) {
                setShowSuccessMessage(true);
                setSuccessMessage('Successfully added to My Courses');
                await checkIfCourseInUserModel(courseProperties.id);
            }
        } catch (error) {
            console.error('Error adding to map:', error);
        }
        await checkIfCourseInUserModel(courseProperties.id);
    };

    const deleteFromMapHandler = async () => {
        try {
            const result = await deleteFromMap(isLoggedIn, courseProperties);
            if (result) {
                setShowSuccessMessage(true);
                setSuccessMessage('Successfully removed from My Courses');
                await checkIfCourseInUserModel(courseProperties.id);
            }
        } catch (error) {
            console.error('Error deleting from map:', error);
        }
        await checkIfCourseInUserModel(courseProperties.id);
    };

    const toggleMapType = () => {
        setMapType(mapType === 'standard' ? 'satellite' : 'standard');
    };

    const getTileLayer = () => {
        if (mapType === 'satellite') {
            return (
                <TileLayer
                    url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                    attribution='&copy; Google Maps'
                />
            );
        }
        return (
            <TileLayer
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                attribution='&copy; OpenStreetMap contributors'
            />
        );
    };

    const hasValidCourseDetails = course => {
        const fieldsToCheck = ['bogey_rating'];
        return fieldsToCheck.some(
            field => course[field] && course[field] !== 'NULL'
        );
    };

    const renderCourseTable = () => {
        if (!course || !hasValidCourseDetails(course)) {
            return <p>Check back later for the Tee details</p>;
        }

        const teeNames = course.tee_name.split(';').map(s => s.trim());
        const genders = course.gender.split(';').map(s => s.trim());
        const pars = course.par_text.split(';').map(s => s.trim());
        const courseRatings = course.course_rating
            .split(';')
            .map(s => s.trim());
        const bogeyRatings = course.bogey_rating.split(';').map(s => s.trim());
        const slopeRatings = course.slope_rating.split(';').map(s => s.trim());
        const ratingF9 = course.ratingf9.split(';').map(s => s.trim());
        const ratingB9 = course.ratingb9.split(';').map(s => s.trim());
        const front9 = course.front_9.split(';').map(s => s.trim());
        const back9 = course.back_9.split(';').map(s => s.trim());
        const bogeyRatingF9 = course.bogey_rating_f9
            .split(';')
            .map(s => s.trim());
        const bogeyRatingB9 = course.bogey_rating_b9
            .split(';')
            .map(s => s.trim());
        const lengthTable = course.length_table.split(';').map(s => s.trim());

        return (
            <table className='course-details-table'>
                <thead>
                    <tr>
                        <th>Tee Name</th>
                        <th>Gender</th>
                        <th>Par</th>
                        <th>Course Rating</th>
                        <th>Bogey Rating</th>
                        <th>Slope Rating</th>
                        <th>Front 9 Rating</th>
                        <th>Back 9 Rating</th>
                        <th>Front 9 Slope</th>
                        <th>Back 9 Slope</th>
                        <th>Bogey Rating Front 9</th>
                        <th>Bogey Rating Back 9</th>
                        <th>Length</th>
                    </tr>
                </thead>
                <tbody>
                    {teeNames.map((name, index) => (
                        <tr key={index}>
                            <td>{name}</td>
                            <td>{genders[index]}</td>
                            <td>{pars[index]}</td>
                            <td>{courseRatings[index]}</td>
                            <td>{bogeyRatings[index]}</td>
                            <td>{slopeRatings[index]}</td>
                            <td>{ratingF9[index]}</td>
                            <td>{ratingB9[index]}</td>
                            <td>{front9[index]}</td>
                            <td>{back9[index]}</td>
                            <td>{bogeyRatingF9[index]}</td>
                            <td>{bogeyRatingB9[index]}</td>
                            <td>{lengthTable[index]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };
    const renderArchitectLinks = architects => {
        if (!architects) return null;
        const architectNames = architects
            .replace(/[\\[\]"]/g, '')
            .split(',')
            .map(name => name.trim());
        return (
            <div>
                {architectNames.map((name, index) => (
                    <React.Fragment key={index}>
                        <a
                            href={`/architects/${encodeURIComponent(name)}`}
                            onClick={e => {
                                e.preventDefault();
                                navigate(
                                    `/architects/${encodeURIComponent(name)}`
                                );
                            }}
                        >
                            {name}
                        </a>
                        {index < architectNames.length - 1 ? ', ' : ''}
                    </React.Fragment>
                ))}
            </div>
        );
    };
    
    
    return (
        <div className='course-container'>
            {renderSuccessMessage()}
            <div className="course-header">
    <h1 className='course-title'>{course.course_name}
        <span className="icons">
            {course.is_pga_tour && <span className="icon" title="PGA Tour Venue">🏆</span>}
            {course.driving_range && <span className="icon" title="Driving Range">🏌️‍♂️</span>}
            {course.length > 7000 && <span className="icon" title="7000+ Yards">💪🏼</span>}
        </span>
    </h1>
    </div>
    <h3 className='course-address'>{course.course_address}</h3>
            {reviews.length > 0 ? (
                <>
                    <h2>
                        {Array(Math.floor(averageRating))
                            .fill()
                            .map((_, i) => (
                                <span key={i}>✪</span>
                            ))}
                        {(averageRating % 1).toFixed(1) >= 0.5 && (
                            <span>½</span>
                        )}
                        <span> (based on {reviews.length} reviews)</span>
                    </h2>
                </>
            ) : (
                <h2 style={{ color: 'black', fontSize: 'medium' }}>
                    No reviews yet.
                    {isLoggedIn && (
                        <span
                            onClick={() =>
                                navigate(`/course-review/${courseId}`)
                            }
                            style={{
                                color: 'blue',
                                cursor: 'pointer',
                                fontSize: 'medium',
                            }}
                        >
                            Be the first to review!
                        </span>
                    )}
                </h2>
            )}
            <div className='course-buttons'>{renderButtons()}</div>
            <div className='Course-TabsList'>
            <Tabs defaultValue={1}>
            <TabsList>
                <Tab value={1}>General Info</Tab>
                <Tab value={2}>Course Stats</Tab>
                <Tab value={3}>Tee Info</Tab>
                <Tab value={4}>Course Map</Tab>
                <Tab value={5}>Reviews</Tab>
            </TabsList>
            <TabPanel value={1}>
                <div className="general-info">
                    <div><strong>Year Built:</strong> {course.year_built || 'Not specified'}</div>
                    <div><strong>Fairways:</strong> {course.fairways || 'Not specified'}</div>
                    <div><strong>Greens:</strong> {course.greens || 'Not specified'}</div>
                    <div><strong>Season:</strong> {course.season || 'Not specified'}</div>
                    <div><strong>Architect(s):</strong> {course.architect ? renderArchitectLinks(course.architect) : 'Not specified'}</div>
                    <div><strong>Driving Range:</strong> {course.driving_range ? 'Available' : 'Not available'}</div>
                    <div><strong>Carts:</strong> {course.carts ? 'Available' : 'Not available'}</div>
                    <div><strong>Rental Clubs:</strong> {course.rental_clubs ? 'Available' : 'Not available'}</div>
                </div>
            </TabPanel>
            <TabPanel value={2}>
                <div className="course-stats">
                   <div><strong>Holes:</strong> {course.holes}</div>
                    <div><strong>Par:</strong> {course.par}</div>
                    <div><strong>Length:</strong> {course.length} yards</div>
                    <div><strong>Slope:</strong> {course.slope}</div>
                    <div><strong>Rating:</strong> {course.rating}</div>
                </div>
            </TabPanel>
            <TabPanel value={3}>
                <Box 
                    width="80%" 
                    maxHeight="400px" 
                    overflowY="auto" 
                    margin="0 auto" 
                    boxSizing="border-box"
                >
                    {renderCourseTable()}
                </Box>
            </TabPanel>
            <TabPanel value={4}>    
            <div className='map-container1'>
                <MapContainer
                    key={mapType}
                    center={position}
                    zoom={17}
                    scrollWheelZoom={false}
                    touch={isMobile}
                    className='leaflet-map'
                >
                    <FeatureGroup ref={setPolylineGroup}>
                        <EditControl
                            key={`edit-control-${mapType}`}
                            position='topright'
                            onCreated={onCreated}
                            onDeleted={onDeleted}
                            onEdited={onEdited}
                            draw={{
                                rectangle: false,
                                polyline: {
                                    metric: false,
                                    showLength: true,
                                },
                                circle: false,
                                circlemarker: false,
                                marker: false,
                                polygon: false,
                            }}
                            touch={isMobile}
                        />

                    {polylines.map((polyline, index) => (
                        <Polyline key={`polyline-${index}`} positions={polyline.latlngs} >
                            <Popup autoClose={false} closeButton={true}>{polyline.popupContent}</Popup>
                        </Polyline>
                    ))}
                    </FeatureGroup>
                    {getTileLayer()}
                    <Marker position={position} icon={golfFlagIcon}>
                        <Popup autoClose={false} closeButton={true}>
                        {course.course_name} <br /> {course.course_address}
                    </Popup>
                    </Marker>
                    <FullscreenControl />
                </MapContainer>
                <button onClick={toggleMapType}>
                    Switch to{' '}
                    {mapType === 'standard' ? 'Satellite' : 'Standard'} Map
                </button>
                </div>
                </TabPanel>
                <TabPanel value={5}>{shouldShowCarousel && (
                <ImageGallery
                    items={images}
                    style={{ width: '50%', height: '50%' }}
                />
            )}

            <div className='reviews-section'>
                <div>
                    <h2>Ratings & Reviews ({reviews.length})</h2>
                    {isLoggedIn && (
                        <button
                            onClick={() =>
                                navigate(`/course-review/${courseId}`)
                            }
                        >
                            Write a Review
                        </button>
                    )}
                </div>
                <div>
                    <h3>Average Rating:</h3>
                    <p>{averageRating.toFixed(1)} out of 5</p>
                    <p>
                        {recommendPercentage}% Recommend {course.course_name}
                    </p>
                    <div className='reviews-list'>
                        {reviews.length > 0 && renderReviewsList()}
                    </div>
                </div>
            </div></TabPanel>
            </Tabs>
            </div>
        </div>
    );
}

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
  };
const Tab = styled(BaseTab)`
  font-family: 'IBM Plex Sans', sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: bold;
  background-color: transparent;
  width: 90%;
  line-height: 1;
  padding: 8px 12px;
  margin: 6px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)`
  max-width: 90vw;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1rem;
  line-spacing: 1;
`;

const TabsList = styled(BaseTabsList)(
    ({ theme }) => `
    width: 100%; // Ensure it takes the full width dynamically
    max-width: 960px; // Optional, depends on your design requirements
    min-width: 960px;
    background-color: ${blue[500]};
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center !IMPORTANT;
    justify-content: center !IMPORTANT;
    align-content: space-between;
    box-shadow: 0px 4px 6px ${
      theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.4)' : 'rgba(0,0,0, 0.2)'
    };
    @media (max-width: 1050px) {
        max-width: 90%;
        min-width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
      `,
  );

export default IndividualCourse;
