import * as React from 'react';
import PropTypes from 'prop-types';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const FlexContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
});

const YardsSlider = styled(Slider)(({ theme }) => ({
    color: '#3a8589',
    height: 5,
    '& .MuiSlider-thumb': {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },
        '& .airbnb-bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    '& .MuiSlider-track': {
        height: 3,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
        opacity: theme.palette.mode === 'dark' ? undefined : 1,
        height: 3,
    },
}));

function YardsThumbComponent(props) {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <span className='yards-bar' />
            <span className='yards-bar' />
            <span className='yards-bar' />
        </SliderThumb>
    );
}

YardsThumbComponent.propTypes = {
    children: PropTypes.node,
};

export default function CustomizedSlider({ value, onChange }) {
    const handleChange = (event, newValue) => {
        onChange(newValue);
    };

    return (
        <FlexContainer sx={{ width: 500 }}>
            <Typography gutterBottom style={{ marginRight: '20px' }}>
                Yards
            </Typography>
            <YardsSlider
                slots={{ thumb: YardsThumbComponent }}
                getAriaLabel={index => (index === 0 ? 'Minumum' : 'Maximum')}
                value={value}
                onChange={handleChange}
                valueLabelDisplay='auto' // Show values on hover, click, or hold
                min={0}
                max={9000}
                step={100}
            />
        </FlexContainer>
    );
}

CustomizedSlider.propTypes = {
    value: PropTypes.arrayOf(PropTypes.number).isRequired,
    onChange: PropTypes.func.isRequired,
};
