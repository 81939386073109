import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
const defaultTheme = createTheme();

export default function SignUp(props) {
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = event => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const password = data.get('password');
        const passwordConfirm = data.get('passwordConfirm');

        if (password !== passwordConfirm) {
            setError('Passwords do not match.');
            return;
        }

        const user = {
            username: data.get('username'),
            email: data.get('email'),
            password: password,
        };

        axios
            .post(backendBaseUrl + '/accounts/signup/', user)
            .then(response => {
                props.onLogin({
                    username: response.data.username,
                    token: response.data.token,
                });
                navigate(`/`);
                // TODO: go to profile? navigate(`/profile/${user.username}`);
            })
            .catch(error => {
                if (error.response.status === 400 
                    && error.response 
                    && error.response.data 
                    && error.response.data) {
                        setError(formatErrorMessages(error.response.data));
                    } else {
                        setError('Sign-up failed. Please check your information.');
                    }
                
                console.error('Sign-up error', error);
            });
    };


    function formatErrorMessages(errorResponse) {
        let errorMessage = 'Sign Up Errors:\n';
    
        // Iterate over the object keys (e.g., 'username', 'password')
        for (const key in errorResponse) {
            // Concatenate the key (e.g., 'username') with its error messages
            // errorMessage += `${key}: `;
            
            // Iterate over the array of error messages for each key
            for (const error of errorResponse[key]) {
                // Remove square brackets and single quotes from the error message
                const cleanedError = error.replace(/[[\]']+/g, '');
                // Concatenate the cleaned error message with the main error message
                errorMessage += `${cleanedError} `;
            }
            // Add a new line for readability
            errorMessage += '\n';
        }
    
        return errorMessage;
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component='main' sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage:
                            'url(https://wallpapers.com/images/high/augusta-national-golf-course-0d2rnm64ngmjxtj4.webp)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: t =>
                            t.palette.mode === 'light'
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component='h1' variant='h5'>
                            Sign up
                        </Typography>
                        <Box
                            component='form'
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{ mt: 3 }}
                        >
                            {error && (
                                <Typography color='error'>{error}</Typography>
                            )}{' '}
                            {/* Display error message */}
                            <TextField
                                margin='normal'
                                required
                                fullWidth
                                id='username'
                                label='Username'
                                name='username'
                                autoComplete='username'
                                autoFocus
                            />
                            <TextField
                                margin='normal'
                                required
                                fullWidth
                                id='email'
                                label='Email Address'
                                name='email'
                                autoComplete='email'
                            />
                            <TextField
                                margin='normal'
                                required
                                fullWidth
                                name='password'
                                label='Password'
                                type='password'
                                id='password'
                                autoComplete='new-password'
                            />
                            <TextField
                                margin='normal'
                                required
                                fullWidth
                                name='passwordConfirm'
                                label='Confirm Password'
                                type='password'
                                id='passwordConfirm'
                                autoComplete='new-password'
                            />
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign Up
                            </Button>
                            <Grid container justifyContent='flex-end'>
                                <Grid item>
                                    <Link href='#' variant='body2'>
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
