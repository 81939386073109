import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import golfFlagImage from '../assets/images/golf.png';
import { Tab, Tabs, Box, Container, Stack, Rating, Link } from '@mui/material';

const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;

function PublicProfile() {
    const { username } = useParams();
    const [courses, setCourses] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${backendUrl}/myapp/user/${username}/courses/`)
            .then(response => response.json())
            .then(data => setCourses(data))
            .catch(error => console.error("Error fetching courses:", error));

        fetch(`${backendUrl}/myapp/user/${username}/reviews`)
            .then(response => response.json())
            .then(data => setReviews(data))
            .catch(error => console.error("Error fetching reviews:", error));
    }, [username]);

    const golfFlagIcon = new L.Icon({
        iconUrl: golfFlagImage,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
    });

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div role="tabpanel" hidden={value !== index} {...other}>
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <div>{children}</div>
                    </Box>
                )}
            </div>
        );
    }
    const renderStarRating = (rating) => {
        return (
            <Stack direction="row" spacing={1}>
                <Rating value={rating} readOnly precision={0.5} />
            </Stack>
        );
    };
    const renderOther = (value, max, symbol) => (
        `${Array.from({ length: value }, () => symbol).join('')}${Array.from({ length: max - value }, () => '▫️').join('')}`
    );

    return (
        <Container>
            <MapContainer center={[39.5, -98.35]} zoom={4} style={{ height: '400px', width: '100%' }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {courses.map(userCourse => (
                    <Marker
                        key={userCourse.course.id}
                        position={[userCourse.course.latitude, userCourse.course.longitude]}
                        icon={golfFlagIcon}
                    >
                        <Popup>
                            <Link component="button" onClick={() => navigate(`/courses/${userCourse.course.slug}`)}>{userCourse.course.course_name}</Link>
                            <br />
                            <span>{userCourse.course.city}, {userCourse.course.state}</span>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
            <Box sx={{ width: '100%', bgcolor: 'background.paper', marginTop: 2 }}>
                <Tabs value={tabValue} onChange={handleChangeTab} centered>
                    <Tab label="Approved Reviews" />
                    <Tab label="Pending Reviews" />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                {reviews.filter(review => review.is_approved).map(review => (
                    <Box key={review.id} sx={{ marginBottom: 2, borderBottom: 1, borderColor: 'divider', padding: 2 }}>
                        <Link component="button"
                            sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer' }}
                            onClick={() => navigate(`/courses/${review.course_slug}`)}>
                            {review.course ? review.course : "Course Name Missing"}
                        </Link>
                        {renderStarRating(review.rating)}
                        <div>Price: {renderOther(review.price, 5, '💰')}</div>
                        <div>Greenspeed: {renderOther(review.greenspeed, 5, '⚡')}</div>
                        <div>Pace of Play: {renderOther(review.pace_of_play, 5, '🚀')}</div>
                        <div>Recommend: {review.recommend ? '👍' : '👎'}</div>
                        <div><span>{review.text}</span></div>
                    </Box>
                ))}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    {reviews.filter(review => !review.is_approved).map(review => (
                        <Box key={review.id} sx={{ marginBottom: 2, borderBottom: 1, borderColor: 'divider', padding: 2 }}>
                            <Link component="button"
                                  sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer' }}
                                  onClick={() => navigate(`/courses/${review.course_slug}`)}>
                                {review.course}
                            </Link>
                            {renderStarRating(review.rating)}
                            <div><span>{review.text}</span></div>
                        </Box>
                    ))}
                </TabPanel>
            </Box>
        </Container>
    );
}

export default PublicProfile;
